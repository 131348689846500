import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Input`}</inlineCode>{` adalah sebuah bidang yang digunakan untuk memperoleh respon dari user.`}</p>
    </PageDescription>
    <h2>{`Input Text`}</h2>
    <h3>{`Variant`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Normal" titleColor="dark" aspectRatio="4:3" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "268px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAABCElEQVQ4y71TaW/CMAzN//+B04CKCQa0jCa9aJuzDzuDcogvjGqWnuI+n7FTgYlFDMOAR7Dc6i8lfEb+JdGYUKoCeS5xyHNIqdD3/XsdzuYJFskSH59zzBYJdml2l/BVTH9lDg4hjLhUCmfcVg8PfAhPOjy2Lay1NDsNYyy0MRGsj9/6nou8NqO9o7lzfNt1EKoosVpv0GuN9fcWm+0uItv/xDNZfiHN9nG2zLEvL7AsK7KncaEX8T5A1HUTA5rmCKkUuIAqChQUcCDnnDZfVjWhihy/BPbhhGyrKc45H8FdC0PX9TQ7ax2c96PROUcV/ZlzV/irnXVLp4nj+IX4l1/vHUzf4dQJTzcrnVYapLr0AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/32c00a0cc75cbb7823422387dabaede4/70900/variant-normal.png",
              "srcSet": ["/static/32c00a0cc75cbb7823422387dabaede4/70900/variant-normal.png 268w"],
              "sizes": "(max-width: 268px) 100vw, 268px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Floating" titleColor="dark" aspectRatio="4:3" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "268px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAABKElEQVQ4y61Ua2uEMBD0//8pP8kJCqJCPYSeiheV6t0ZWxPxNU3S05a+4MSFYdhsMmx2N9Gws2nzPOM3SFv4IcG/AlvElGBNG9xqisv1JnBFVV3w1rbbM0zPBM+nGKcoQZykeDqGKMtqFXwU+1/5qzNNs8C0KbM1w5pSvIgryhq2LQPjHC1jCoxx5StmfF37zpx3CtLXmuYVVDRGNmerLeUZxhEaIRnMwwFNQ2GaJizLUvB9H7ZtQ9d1uK4Lx3HgeR4Mw0AQBIjjGLbYF4bhKjxKwVqMTHA8oqwqpGkKkmWCz8gER1EsDibIiwJ5nqs1GSOECL9AkiRiIkr0w4C+78G7DppU/VCf7un/7PJn0bHuWeKykVJMQQhr/4/IfMcOb3nz2Oz927wDYjuWAMxF4tQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/68cbe28fa2fc8750d182c8b58ba9c788/70900/varian-floating.png",
              "srcSet": ["/static/68cbe28fa2fc8750d182c8b58ba9c788/70900/varian-floating.png 268w"],
              "sizes": "(max-width: 268px) 100vw, 268px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Size`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Medium" titleColor="dark" aspectRatio="4:3" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "268px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAAsklEQVQ4y+VT7QqDMAzs+7/kvvwcWMG2zojtrWnVTZDpwH8GrpdeQpoEKnCwiZMWdM4dBsHHZN/+2n1Lnzsk6tF1FJkogCjemTm21EY9xDq8PNj/FOx7pFmOStbIixL3R4IsL1CUT1yuNx+LPuckaQZZx7xKyrkzLmp55KnDRukAbQyUZ6X1qKmgBWgTczwrD9O2iyncVHBr0fyytSu6tRgGxjDvVfxa8L+2u8O9OOnXewMv9KNgUgO79gAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/32c00a0cc75cbb7823422387dabaede4/70900/size-medium.png",
              "srcSet": ["/static/32c00a0cc75cbb7823422387dabaede4/70900/size-medium.png 268w"],
              "sizes": "(max-width: 268px) 100vw, 268px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Large" titleColor="dark" aspectRatio="4:3" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "268px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAA1ElEQVQ4y+VTXQ+CMAz0//9HFUFAHYoR9r1x0gKixkd8MC659MpKe7fBCguv1W807LpuEXDDiSyxqBcrjD0JIcD7d/ghhg/POM48xjhbNsZCa8MbbsRzsbUOzg2YGs0ChnptzKzQOY80y3ESAkJUPd9jm6QoygOD+HqTIC9KlIcjkl3KsTpfkOUF6vrKQ0nloNBaSKlwuzU8iXjbSiil0TQtWikhlYLSuneiOSdQTjW2f/9FYQjxYYetjbZIuXs6Bs6df9ieclJHR/C9W170O/y/f/kOJouhr84IHUYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/d58694a1488a073ea256d83a9c40dd87/70900/size-large.png",
              "srcSet": ["/static/d58694a1488a073ea256d83a9c40dd87/70900/size-large.png 268w"],
              "sizes": "(max-width: 268px) 100vw, 268px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      